<template>
  <v-container fluid class="down-top-padding pt-0">
    <v-row>
      <v-col cols="12">
        <div>
          <change-password
            v-if="changePasswordDialog"
            userType="member"
            :selectedUser="selectedAssociate"
            :dialogStatus="changePasswordDialog"
            @close="changePasswordDialog = false"
            @refresh="initialize(1)"
          ></change-password>
          <create-and-edit-associate
            v-if="associateDialog"
            :selectedMember="selectedMember"
            :selectedAssociateData="selectedAssociate"
            :dialogStatus="associateDialog"
            @close="associateDialog = false"
            @refresh="initialize(1)"
          ></create-and-edit-associate>
          <confirmation-dialog
            @close="dialogDelete = false"
            @confirm="deleteItemConfirm"
            :dialog="dialogDelete"
            :dialogHeadline="deleteConfirmationHeadline"
          ></confirmation-dialog>
          <v-data-table
            :loading="loading"
            class="elevation-0 pb-4 pt-4"
            :headers="headers"
            :items="associates"
            :expanded.sync="expanded"
            show-expand
            item-key="id"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :disable-sort="true"
          >
            <template v-slot:top class="px-3">
              <v-toolbar flat class="mb-4 px-3">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('associate.search')"
                  @input="initialize(1)"
                  hide-details
                  outlined
                ></v-text-field>
                <v-spacer />
                <v-autocomplete
                  v-model="parentId"
                  :items="getMembersList"
                  item-text="company_name"
                  item-value="id"
                  :label="$t('associate.member')"
                  outlined
                  hide-details
                  :value="parentId"
                  @change="initialize(1)"
                  return-value
                ></v-autocomplete>
              </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col cols="12"> </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("associate.parentId") }}</b> :
                    {{ item.parent_id }}
                  </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("associate.representativePhone") }}</b> :
                    {{ item.user_detail.representative_phone }}
                  </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("associate.services") }}</b> :
                    {{ getServices(item.services) }}
                  </v-col>
                  <v-col cols="4"
                    ><b>{{ $t("associate.parentServices") }}</b> :
                    {{ getServices(item.parent_services) }}
                  </v-col>
                </v-row>
              </td>
            </template>
            <template v-if="manageUser" v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon color="primary">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    @click="
                      selectedAssociate = item;
                      changePasswordDialog = true;
                    "
                    ><v-icon color="#1b5e20" medium>mdi-account-key</v-icon
                    >{{ $t("member.changePassword") }}</v-list-item
                  >
                  <v-list-item
                    @click="
                      selectItem(item);
                      dialog = true;
                    "
                    ><v-icon color="#1b5e20" medium>mdi-pencil</v-icon
                    >{{ $t("associate.update") }}</v-list-item
                  >
                  <v-list-item
                    @click="
                      selectedAssociate = item;
                      dialogDelete = true;
                    "
                    ><v-icon color="#1b5e20" medium>mdi-delete</v-icon
                    >{{ $t("associate.delete") }}</v-list-item
                  >
                  <v-list-item>
                    <v-switch
                      v-model="item.is_active"
                      :label="
                        item.is_active
                          ? $t('member.block')
                          : $t('member.unblock')
                      "
                      @change="activation(item)"
                    ></v-switch>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-if="associates.length > 0"
              v-model="meta.current_page"
              :length="meta.last_page"
              @input="initialize"
            ></v-pagination>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import ConfirmationDialog from "../components/common/ConfirmationDialog";
import CreateAndEditAssociate from "../components/members/CreateAndEditAssociate";
import { VIEW_USER, MANAGE_USER } from "@/constants/permission";
import { showSnackbar } from "../eventbus/action.js";
import ChangePassword from "../components/common/ChangePassword";
export default {
  name: "Associates",
  components: {
    ChangePassword,
    CreateAndEditAssociate,
    ConfirmationDialog,
  },
  data() {
    return {
      parentId: "",
      manageUser: false,
      viewUser: false,
      associateDialog: false,
      changePasswordDialog: false,
      selectedAssociate: {
        user_detail: {},
      },
      dialogDelete: false,
      defaultItem: {
        user_detail: {},
      },
      loading: false,
      deleteConfirmationHeadline: this.$t("associate.deleteHeadline"),
      page: 1,
      pageCount: 1,
      itemsPerPage: 50,
      expanded: [],
      search: "",
      singleExpand: false,
      headers: [
        { text: "", value: "data-table-expand", width: "5%" },
        {
          text: this.$t("associate.id"),
          align: "start",
          value: "id",
          width: "10%",
        },
        {
          text: this.$t("associate.companyName"),
          value: "user_detail.company_name",
          width: "15%",
        },
        {
          text: this.$t("associate.representativeName"),
          value: "user_detail.representative_name",
          width: "15%",
        },
        {
          text: this.$t("associate.email"),
          value: "email",
          width: "30%",
        },
        {
          text: this.$t("associate.password"),
          value: "p_token",
          width: "15%",
        },
        {
          text: this.$t("associate.actions"),
          value: "actions",
          width: "10%",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      members: "members/getMembersList",
      associates: "associates/getAssociates",
      meta: "associates/getAssociateMeta",
      user: "user/getUser",
    }),

    getMembersList() {
      return [
        {
          id: "",
          company_name: this.$t("associate.allAssociates"),
        },
        ...this.members,
      ];
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.parentId = Number(this.$route.query.id);
    }
    this.initialize(1);
  },
  methods: {
    ...mapActions({
      deleteAssociate: "associates/deleteAssociate",
      getAssociates: "associates/getAssociates",
      setHeaderMenuItems: "common/setHeaderMenuItems",
      activateMember: "members/activateMember",
      getMembers: "members/getMembersList",
    }),

    async initialize(page) {
      this.page = page;
      let menuItems = [
        { title: this.$t("commonLayout.members"), path: "/pages/members" },
        {
          title: this.$t("commonLayout.associates"),
          path: "/pages/associates",
        },
        {
          title: this.$t("commonLayout.coreBuying"),
          path: "/pages/core-buying",
        },
      ];
      const userPermission = this.user.permission.map((val) => val.name);
      this.manageUser = userPermission.includes(MANAGE_USER);
      this.viewUser = userPermission.includes(VIEW_USER);
      if (!this.viewUser) {
        menuItems = [];
      }
      this.setHeaderMenuItems(menuItems);
      this.loading = true;
      try {
        let filter = {};
        if (this.parentId && this.parentId !== "") {
          filter = { parent_id: this.parentId };
        }
        await this.getMembers();
        await this.getAssociates({
          search: this.search,
          page: this.page,
          ...filter,
        });
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      } finally {
        this.loading = false;
      }
    },

    getServices(data) {
      return data.map((val) => val.name).join(", ");
    },

    selectItem(item) {
      this.associateDialog = true;
      this.selectedAssociate = _.cloneDeep(item);
      this.selectedMember = {
        services: item.parent_services,
        user_detail: [],
      };
    },

    async deleteItemConfirm() {
      this.loading = true;
      try {
        await this.deleteAssociate({
          id: this.selectedAssociate.id,
        });
        this.dialogDelete = false;
        this.initialize(1);
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      } finally {
        this.loading = false;
      }
    },

    async activation(associate) {
      this.loading = true;
      try {
        await this.activateMember({
          id: associate.id,
        });
        this.dialogDelete = false;
        this.initialize(this.page);
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      } finally {
        this.loading = false;
      }
    },
  },

  destroyed() {
    this.setHeaderMenuItems([]);
  },
};
</script>
